<template>
  <OfferSandboxBase @reloadOfferSample="reload()">
    <OfferDetails :tender :offer :sandboxMode="true" />
  </OfferSandboxBase>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import {
  Tender,
  OfferSandboxBase,
} from '@dims/components';
import OfferDetails from '@/components/Offers/OfferDetails/OfferDetails.vue';
import { Offer5086 } from '@/models/Offer';
import { TenderData5086 } from '@/models/Tender';

const { tender } = defineProps<{ tender: Tender }>();

const offer = ref<Offer5086>(getOfferSample());

function reload() {
  // (re)create sandbox offer every time the dialog is shown
  offer.value = getOfferSample();
}

function getOfferSample(): Offer5086 {
  // No real offer exists yet, this offer represents how an offer would look when just created
  return {
    id: 'sample',
    tenderData: tender.data as TenderData5086,
    cvrNumber: 'DUMMY',
    companyName: 'DUMMY',
    data: {
      brand: '',
      model: '',
      variant: '',
      equipmentLine: '',
      engineSize: '',
      power: '',
      propellantType: undefined,
      emission: undefined,
      vehicleClassType: undefined,
      consumptionElectric: undefined,
      chargingStationPrice: undefined,
      chargingSubscription: undefined,
      comfortDistanceSingleSeatRow: undefined,
      comfortDistanceDoubleSeatRow: undefined,
      seatHeightFront: undefined,
      seatHeightBack: undefined,
      heightFloorToCeiling: undefined,
      lengthDriversSeatToTrunk: undefined,
      lengthBackPanelBackSeat: undefined,
      isoFixBackSeat: undefined,
      lengthCargoSpace: undefined,
      volumeCargoSpace: undefined,
      widthBetweenWheelArches: undefined,
      heightBottomToCeiling: undefined,
      volumeTrunk: undefined,
      weightTruckBedTotal: undefined,
      truckBedSize: undefined,
      lengthTotal: undefined,
      heightTotal: undefined,
      heightToFloor: undefined,
      weightTotal: undefined,
      weightCargo: undefined,
      energyClass: undefined,
      priceTotal: undefined,
      greenOwnerTax: undefined,
      registrationTax: undefined,
      priceService: undefined,
      priceRepairs: undefined,
      priceCollectDeliverService: undefined,
      priceServicePeriod: undefined,
      priceTireAgreement: undefined,
      priceDelivery: undefined,
      priceDeliveryAtCustomer: undefined,
      priceBuyback: undefined,
      priceBuyCustomersCar1: undefined,
      priceBuyCustomersCar2: undefined,
      priceBuyCustomersCar3: undefined,
      priceBuyCustomersCar4: undefined,
      priceBuyCustomersCar5: undefined,
      priceVehicleModificationFixed: undefined,
      priceVehicleModificationHourlyRate: undefined,
    },
    readyForAwarding: false,
    documentationRequested: false,
    documentationApproved: false,
    agreementSignedBySupplier: false,
    agreementSignedByCustomer: false,
    tenderId: tender.id,
    agreementName: tender.agreementName,
    specificationAccepted: false,
    createdDate: '',
    status: 'Draft',
    artifacts: [],
    isEvaluated: false,
    sender: {
      organizationNumber: '',
      cvrNumber: '',
      fullName: '',
      companyName: '',
      email: '',
      address: '',
      zip: '',
      city: '',
      userId: '',
    },
  };
}

</script>
