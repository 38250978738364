<template>
  <div>
    <LoadingSpinner :visible="isLoading || isDownloading" loadingMessage="Henter kravspecifikation" />
    <div v-if="!isLoading && questionnaire">
      <v-btn variant="text" @click="downloadPdf()" class="secondary-button bg-transparent float-right">Download som PDF<v-icon end>mdi-download</v-icon></v-btn>
      <div>
        <div class="font-weight-bold">
          <div>{{ tender.user.companyName }}</div>
          <div>{{ tender.description }}</div>
          <div>{{ tender.friendlyId }}</div>
        </div>
        <h1 class="mt-0">Kravspecifikation</h1>
        <div
          v-for="question of questionnaire.questionsWithAnswers"
          :key="question.questionId"
        >
          <DDQuestionComponent
            :class="{ 'move-down': question.questionType === 'HEADLINE1' }"
            :id="
              question.questionType === 'HEADLINE1'
                ? question.questionId
                : ''
            "
            :question
            :prefix="questionnaire.getPrefix(question)"
            :required="question.required"
            :readonly="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { DDQuestionComponent, downloadFile, QuestionnaireWrapper, Tender, useBackend, useStore } from '@dims/components';

const emit = defineEmits<{ loading: [boolean] }>();
const { tender } = defineProps<{ tender: Tender }>();
const store = useStore();
const backend = useBackend();
const isLoading = ref(false);
const isDownloading = ref(false);
const questionnaire = ref<QuestionnaireWrapper | null>(null);

onMounted(async () => {
  await fetchQuestionnaire();
});

async function fetchQuestionnaire() {
  isLoading.value = true;
  emit('loading', true);
  try {
    const returnedQuestionnaire = await backend.documentDrafterService.getReadonlyQuestionnaire(
      tender.id,
      'specification',
    );
      // 50.86 has specification as a document drafter questionnaire
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const questionnaireConfig = tender.agreementConfiguration.questionnaires.specification!;
    questionnaire.value = new QuestionnaireWrapper(
      questionnaireConfig,
      returnedQuestionnaire,
    );
  } catch (error) {
    console.error(error);
    const errorMessage = error instanceof Error ? error.message : String(error);
    store.setSnackbarText(errorMessage);
  }
  emit('loading', false);
  isLoading.value = false;
}

async function downloadPdf() {
  if (questionnaire.value) {
    isDownloading.value = true;
    try {
      const pdfBlob = await backend.pdfService.specificationAsPdf(tender);
      downloadFile(pdfBlob, `Kravspecifikation-${tender.friendlyId}.pdf`);
    } catch (e) {
      console.error(e);
      store.setSnackbarText('Kan ikke hente fil');
    } finally {
      isDownloading.value = false;
    }
  }
}

</script>

<style scoped>

.text-field {
  padding: 15px 20px 15px 20px;
  justify-content: space-between;
}

</style>
