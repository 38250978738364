<template>
  <div>
    <div class="d-flex flex-row align-center">
      <h3>Titel</h3>
      <DimsTooltip>
        <p>
          Giv en kort og præcis titel til {{ createAsTemplate ? 'din skabelon' : 'dit indkøb' }}.
          Titlen må ikke indeholde navne på specifikke produkter, producenter eller leverandører. Du kan ændre titlen senere.
        </p>
      </DimsTooltip>
    </div>
    <EditDescription :store :createAsTemplate />

    <div class="d-flex flex-row mt-0 align-center">
      <h3>Biltype</h3>
      <DimsTooltip>
        <p>
          Vælg din biltype. Du skal være opmærksom på, at du kun
          kan vælge én biltype. Skal du indkøbe flere biltyper, skal du oprette
          flere indkøb.
        </p>
      </DimsTooltip>
    </div>
    <VehicleSelection />

    <div class="d-flex flex-row mt-4 mb-2 align-center">
      <h3>Drivmiddel</h3>
      <DimsTooltip>
        <p>
          Vælg køretøjets drivmiddel. Du har mulighed for at vælge flere
          drivmidler, hvis du ikke har specifikke præferencer og er interesseret
          i at modtage tilbud på flere typer drivmidler.
        </p>
        <p>
          <strong>Bemærk:</strong> Du modtager kun et tilbud per leverandør.
          Ønsker du at give leverandørerne mulighed for at indsende flere
          tilbud, fx med forskellige drivmidler, vælger du "sideordnede tilbud"
          senere i din opfordringsskrivelse.
        </p>
      </DimsTooltip>
    </div>
    <SelectPropellant />

    <div class="d-flex flex-row mt-6 align-center">
      <h3>Region</h3>
      <DimsTooltip>
        <p>
          Her skal du vælge hvor du skal have leveret køretøjet. Du skal være
          opmærksom på, at nogle leverandører kun leverer til bestemte regioner.
        </p>
        <p>
          Hvis du skal have leveret køretøjer på tværs af flere regioner, skal
          du vælge den region hvor størstedelen af køretøjerne skal leveres.
          Obs. Du angiver de konkrete leveringssteder i din
          opfordringsskrivelse.
        </p>
      </DimsTooltip>
    </div>
    <DeliveryRegion />

    <div class="d-flex flex-row mt-1 align-center">
      <h3>Tildelingskriterier</h3>
      <DimsTooltip>
        <p>
          Vælg det tildelingskriterie du vil evaluere de indkomne tilbud efter.
          Tildelingskriteriet afhænger som udgangspunkt af, hvorvidt køretøjet
          efter jeres egne retningslinjer udgør en arbejdsplads.
        </p>
        <ul>
          <li>
            Udgør køretøjet en arbejdsplads, skal du som udgangspunkt evaluere
            efter <strong>"Bedste forhold mellem pris og kvalitet (APV)"</strong>,
            dvs. på baggrund af evaluering af både pris og kvalitet, hvor kvalitet
            opgøres ved en arbejdspladsvurdering (APV).
          </li>
          <li>
            Udgør køretøjet <strong>ikke</strong> en arbejdsplads, skal du som
            udgangspunkt evaluere efter <strong>"Omkostninger (TCO)"</strong>,
            dvs. på baggrund af indkøbets TCO-pris (Total Cost of Ownership), som
            omfatter indkøbspris og livscyklusomkostninger.
          </li>
        </ul>
      </DimsTooltip>
    </div>
    <AwardCriteria />

    <div class="d-flex flex-row mt-6 align-center" v-if="!createAsTemplate">
      <h3>Tilbudsfrist</h3>
      <DimsTooltip>
        <p>
          Sæt en tilbudsfrist for leverandører. Du kan ændre tilbudsfristen frem til du offentliggør materialet.
        </p>
        <p>
          Tilbudsfristen er min. 10 kalenderdage fra den dag, du offentliggør materialet (reelt 11 dage pga. fristberegningen)
          jf. Udbudslovens §105, stk. 4. Som ordregiver er det dit ansvar, at fristerne opfylder udbudsloven.
        </p>
        <p>
          Giv gerne længere tid! Tilbudsgiverne skal have rimelig tid til at udarbejde tilbud afhængig af indkøbets kompleksitet.
        </p>
      </DimsTooltip>
    </div>
    <CreateTenderDeadline v-if="!createAsTemplate" />
  </div>
</template>

<script setup lang="ts">
import { EditDescription, useStore } from '@dims/components';
import { AwardCriteria, CreateTenderDeadline, DeliveryRegion, SelectPropellant, VehicleSelection } from './steps';

const { createAsTemplate = false } = defineProps<{
  /** when set to true it will create a template instead of a draft */
  createAsTemplate?: boolean }>();
const store = useStore();

</script>

<style scoped>
h3 {
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>
