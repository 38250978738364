<template>
  <TenderTerminationDialogBase
    :tender
    :disabled
    showSKIDiscountShare
    :conditionalOffers
    :unconditionalOffers
    :agreementSpecific="agreementSpecificHandler"
  >
    <template #page1-tender>
      <v-row>
        <v-col cols="4">
          <strong>Biltype:</strong>
          {{ vehicleTypeLabels(vehicleType) }}
        </v-col>
        <v-col cols="4">
          <strong>Region:</strong>
          {{ regionTypeLabels(regionType) }}
        </v-col>
        <v-col cols="4">
          <strong>Tildelingskriterium:</strong>
          {{ awardTypeLabels(awardType) }}
        </v-col>
      </v-row>
    </template>
    <template #page1-winning-offer="{ offer }">
      <v-row>
        <v-col cols="4">
          <strong>Antal køretøjer (initialt):</strong>
          <div>
            {{
              validCarsBoughtInitially ? carsBoughtInitially : ""
            }}
            <NumericInput
              v-if="!validCarsBoughtInitially"
              class="bg-white ma-0"
              v-model="carsBoughtInitiallyTemp"
              required
              density="compact"
            />
          </div>
        </v-col>
        <v-col cols="4">
          <strong>Optioner på køretøjer:</strong>
          <div>
            {{
              validOptionalCarsAfterAgreement
                ? optionalCarsAfterAgreement
                : ""
            }}
            <NumericInput
              v-if="!validOptionalCarsAfterAgreement"
              class="bg-white ma-0"
              v-model="optionalCarsAfterAgreementTemp"
              required
              suffix="biler"
              density="compact"
              data-cy="optionalCarsAfterAgreement"
            />
          </div>
        </v-col>
        <v-col cols="4" v-if="optionalCarsAfterAgreementTemp > 0">
          <strong>Optionsperiode:</strong>
          <div>
            {{
              validOptionalCarsAfterAgreementPeriodInMonths
                ? optionalCarsAfterAgreementPeriodDescription
                : ""
            }}
            <v-select
              v-if="!validOptionalCarsAfterAgreementPeriodInMonths"
              class="bg-white ma-0"
              v-model="optionalCarsAfterAgreementPeriodInMonthsTemp"
              :items="optionalCarsAfterAgreementPeriods"
              :hide-details="true"
              suffix="måneder"
              data-cy="optionalCarsAfterAgreementPeriodInMonthsTemp"
            ></v-select>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <strong>Drivmiddel:</strong>
          <div>
            {{ propellantTypeLabels(selectedPropellantType(offer)) }}
          </div>
        </v-col>
      </v-row>
    </template>
    <template #page2a>
      <p
        class="mt-5 ma-0"
        v-if="tender.data.useLeasingCompanyAsBuyer"
      >
        <strong>Bemærk:</strong> Da dit indkøb foretages med KommuneKredit som
        køber og finansieringsselskab, skal du have en fuldmagt fra
        KommuneKredit før du offentliggør. Har du ikke en fuldmagt så
        kontakt KommuneKredit på
        <a
          href="mailto:bil@kommuneleasing.dk"
        >bil@kommuneleasing.dk</a>
      </p>
    </template>
    <template #page2b>
      <p v-if="tender.data.useLeasingCompanyAsBuyer">
        KommuneKredit modtager tillige notifikation om din tildeling.
      </p>
    </template>
  </TenderTerminationDialogBase>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import {
  labelForRegion,
  Offer,
  Region,
  TenderTerminationDialogBase,
  hasValue,
  TenderData,
} from '@dims/components';
import { PropellantType, VehicleType } from '@/models';
import { vehicleTypeLabels } from '@/models/VehicleTypes';
import { propellantTypeLabels } from '@/models/PropellantTypes';
import { Tender5086 } from '@/models/Tender';
import { Offer5086 } from '@/models/Offer';
import { awardCriteriaType } from '@/services';

const { tender, disabled = false } = defineProps<{
  tender: Tender5086,
  disabled?: boolean,
  conditionalOffers: Offer[],
  unconditionalOffers: Offer[],
}>();

const awardTypeLabels = awardCriteriaType.getLabel;
const optionalCarsAfterAgreementTemp = ref(0);
const optionalCarsAfterAgreementPeriodInMonthsTemp = ref(0);
const carsBoughtInitiallyTemp = ref(1);
const agreementSpecificHandler = {
  isTenderChanged: () => isTenderChanged(),
  validTenderAndOfferData: () => validTenderAndOfferData(),
  updateTenderData: () => updateTenderData() as Partial<TenderData>,
};

function regionTypeLabels(type?: Region): string {
  return type ? labelForRegion(type) : '';
}

onMounted(() => {
  const td = tender.data;
  optionalCarsAfterAgreementTemp.value = td.optionalCarsAfterAgreementSelected
    ? (td.optionalCarsAfterAgreement ?? 0) : 0;
  optionalCarsAfterAgreementPeriodInMonthsTemp.value = td.optionalCarsAfterAgreementPeriodInMonths ?? 0;
  carsBoughtInitiallyTemp.value = td.carsBoughtInitially ?? 1;
});

const vehicleType = computed({
  get() {
    return tender.data.vehicleType ?? undefined;
  },
  set(value: VehicleType | undefined) {
    tender.data.vehicleType = value;
  },
});

const regionType = computed({
  get() {
    return tender.data.region ?? undefined;
  },
  set(value: Region | undefined) {
    tender.data.region = value;
  },
});

const awardType = computed(() => tender.awardCriteriaType);

const optionalCarsAfterAgreementPeriods = computed(() => [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);

const optionalCarsAfterAgreement = computed({
  get() {
    const td = tender.data;
    return td.optionalCarsAfterAgreementSelected ? (td.optionalCarsAfterAgreement ?? 0) : 0;
  },

  set(value: number) {
    optionalCarsAfterAgreementTemp.value = value;
  },
});

const optionalCarsAfterAgreementPeriodInMonths = computed({
  get() {
    return tender.data.optionalCarsAfterAgreementPeriodInMonths ?? 0;
  },

  set(value: number) {
    optionalCarsAfterAgreementPeriodInMonthsTemp.value = value;
  },
});

function selectedPropellantType(offer?: Offer5086): PropellantType | undefined {
  return offer?.data.propellantType ?? undefined;
}

const carsBoughtInitially = computed({
  get() {
    return tender.data.carsBoughtInitially ?? 1;
  },

  set(value: number) {
    carsBoughtInitiallyTemp.value = value;
  },
});

const validOptionalCarsAfterAgreement = computed((): boolean => !((tender.data.optionalCarsAfterAgreementSelected ?? true)
      && (!hasValue(tender.data.optionalCarsAfterAgreement))));

const optionalCarsAfterAgreementPeriodDescription = computed((): string => {
  const desc = tender.data.optionalCarsAfterAgreementPeriodDescription ?? '';
  const monthsFromDesc = Number.parseInt(desc, 10);
  const months = tender.data.optionalCarsAfterAgreementPeriodInMonths ?? 0;
  return Number.isNaN(monthsFromDesc) ? `${months} måneder` : desc;
});

const validOptionalCarsAfterAgreementPeriodInMonths = computed((): boolean => {
  const desc = tender.data.optionalCarsAfterAgreementPeriodDescription ?? '';
  const monthsFromDesc = Number.parseInt(desc, 10);
  const months = tender.data.optionalCarsAfterAgreementPeriodInMonths;
  return !Number.isNaN(monthsFromDesc) || hasValue(months);
});

const validCarsBoughtInitially = computed(() => carsBoughtInitially.value && carsBoughtInitially.value > 1);

function validTenderAndOfferData(): boolean {
  const optionalCarsValid = validOptionalCarsAfterAgreementPeriodInMonths.value
      || optionalCarsAfterAgreementTemp.value === 0
      || optionalCarsAfterAgreementPeriodInMonthsTemp.value > 0;
  return (
    optionalCarsValid
      && carsBoughtInitiallyTemp.value > 0
  );
}

function updateTenderData() {
  tender.data.optionalCarsAfterAgreement = optionalCarsAfterAgreementTemp.value;
  tender.data.optionalCarsAfterAgreementPeriodInMonths = optionalCarsAfterAgreementPeriodInMonthsTemp.value;
  tender.data.carsBoughtInitially = carsBoughtInitiallyTemp.value;
  const data = {
    carsBoughtInitially: carsBoughtInitiallyTemp.value,
    optionalCarsAfterAgreement: optionalCarsAfterAgreementTemp.value,
    optionalCarsAfterAgreementPeriodInMonths:
      optionalCarsAfterAgreementPeriodInMonthsTemp.value,
  };
  return data;
}

function isTenderChanged(): boolean {
  return (
    optionalCarsAfterAgreement.value !== optionalCarsAfterAgreementTemp.value
      || optionalCarsAfterAgreementPeriodInMonths.value
      !== optionalCarsAfterAgreementPeriodInMonthsTemp.value
      || carsBoughtInitially.value !== carsBoughtInitiallyTemp.value
  );
}

</script>
