<template>
  <PublishTenderConfirmationDialogBase :tender :isLoading>
    <template #additional-row-1>
      <v-row>
        <v-col>
          <small>Type</small>
          <div class="d-flex bg-canvas text-primary text-field">
            {{ getVehicleTypeLabel(tender.data.vehicleType) }}
          </div>
        </v-col>
        <v-col>
          <small>Region</small>
          <div class="d-flex bg-canvas text-primary text-field">
            {{ getRegionLabel(tender.data.region) }}
          </div>
        </v-col>
      </v-row>
    </template>

    <template #additional-row-2>
      <br />
    </template>

    <template #additional-row-3>
      <v-row>
        <v-col>
          <p v-if="tender.data.useLeasingCompanyAsBuyer">
            <strong>Bemærk:</strong>
            Du har valgt at foretage indkøbet som en finansiel leasing med
            KommuneKredit som køber og finansieringsselskab. Du skal derfor have
            en fuldmagt fra KommuneKredit <strong>før</strong> du
            offentliggør.<br />
            Har du ikke en fuldmagt så kontakt KommuneKredit på
            <a href="mailto:bil@kommuneleasing.dk">bil@kommuneleasing.dk</a>
          </p>
          <p>
            Når du vælger 'Offentliggør og send til leverandører', igangsætter
            du
            <strong>straks</strong>
            dit indkøb. Dit materiale vil med det samme være tilgængeligt for
            alle tilbudsgivere i dine valgte kategorier.<br />
            <span v-if="tender.data.useLeasingCompanyAsBuyer">
              KommuneKredit modtager tillige notifikation om dit indkøb.
            </span>
          </p>
          <p>
            Hvis du efterfølgende får brug for at annullere dit indkøb, kan du
            gøre det under fanen 'Overblik'.
          </p>
        </v-col>
      </v-row>
    </template>
  </PublishTenderConfirmationDialogBase>
</template>

<script setup lang="ts">

import {
  Region,
  PublishTenderConfirmationDialogBase,
  labelForRegion,
} from '@dims/components';
import { VehicleType } from '@/models';
import { vehicleTypeLabels } from '@/models/VehicleTypes';
import { Tender5086 } from '@/models/Tender';

const { isLoading = false } = defineProps<{
  tender: Tender5086,
  isLoading?: boolean }>();

function getRegionLabel(type?: Region | null): string {
  return type ? labelForRegion(type) : '';
}

function getVehicleTypeLabel(type?: VehicleType | null): string {
  return type ? vehicleTypeLabels(type) : '';
}

</script>

<style scoped>
:deep(.text-field) {
  padding: 15px 20px 15px 20px;
  justify-content: space-between;
}
</style>
